import { useQueryFeatureFlags } from '@/services/FeatureFlags/queries'
import { cleanFlags } from '@/services/FeatureFlags/utils'
import { useIsGuestUser } from '@/services/User/hooks'
import { featureFlagsAtom } from '@/state/FeatureFlags/atoms'
import { logError } from '@/utils/logger'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { useEffect } from 'react'
import { useCurrentRouteDataContext } from '@/context/RouteDataContext'

export const useLoadFeatureFlagsIntoAtom = () => {
  const currentRouteData = useCurrentRouteDataContext()
  const isGuest = Boolean(useIsGuestUser() && currentRouteData?.authentication)
  const setFeatureFlags = useSetAtom(featureFlagsAtom)
  const { isFetched, isPending, error, data } = useQueryFeatureFlags()

  // hydrate jotai atom from hydrated feature flags query
  useHydrateAtoms([
    [
      featureFlagsAtom,
      {
        error,
        isFetched,
        isPending,
        flags: cleanFlags(data, isGuest),
      },
    ],
  ])

  useEffect(() => {
    const flags = cleanFlags(data, isGuest)
    setFeatureFlags({
      isPending,
      error,
      isFetched,
      flags,
    })
  }, [data, isGuest, isPending, error, isFetched, setFeatureFlags])

  return null
}

export const useSyncFeatureFlagsWithGlobalVariable = () => {
  const { flags, isPending } = useAtomValue(featureFlagsAtom)

  useEffect(() => {
    if (!isPending) {
      try {
        Object.entries(flags).forEach(([key, value]) => {
          // e.g. __SEGWAY_FEATURE_FLAG_real_time_order_tracking=true
          // @ts-expect-error indexing window variable
          window[`__SEGWAY_FEATURE_FLAG_${key}`] = value
        })
      } catch (e) {
        // dont wanna accidentally bring down the app, need alerting around this error
        logError(
          new Error('Unable to set feature flag global variables'),
          {},
          {
            cause: e,
          }
        )
      }
    }
  }, [isPending, flags])
}
