import { useMemo } from 'react'
import { useUser } from '@/services/User/hooks'
import { getUserZip } from '@/services/User/utils'
import { useGuestUserContext } from '@/context/GuestUserContext'
import {
  getGuestStoreParams,
  getGuestStoreParamsSSR,
} from '@/utils/dataFetching/guestStoreParams'
import { type User } from '@/services/User/types'
import { type GetServerSidePropsContext } from 'next'
import { useStoreContext } from '@/context/GlobalStoreContext'

export const getStoreParams = (user: User): StoreParams => {
  const { id, metro_id, store_id, store_location_id } = user ?? {}
  return {
    user_id: id,
    metro_id: metro_id ?? null,
    store_id: store_id ?? null,
    store_location_id: store_location_id ?? null,
    zip: getUserZip(user),
  }
}

export const useStoreParams = (): StoreParams => {
  const user = useUser()
  const { guestStore, guestAddress } = useGuestUserContext() ?? {}
  const { user_id, metro_id, store_id, store_location_id, zip } = user?.id
    ? getStoreParams(user)
    : getGuestStoreParams({ guestStore, guestAddress })
  return useMemo(
    () => ({
      zip,
      user_id,
      metro_id,
      store_id,
      store_location_id,
    }),
    [user_id, metro_id, store_id, store_location_id, zip]
  )
}
export type StoreParams = {
  /**
   * @deprecated user_id should only be accessed from user hooks e.g. useUser, useUserId, etc
   */
  user_id: number
  zip: string
  metro_id: number | null
  store_id: number | null
  store_location_id: number | null
}

export type GlobalStoreParams = {
  metro_id: number
  store_id: number
  store_location_id: number
}

export type StoreParamsAsKey = {
  storeParams: StoreParams
}

export const getStoreParamsSSR = (
  ssrContext: GetServerSidePropsContext,
  user: User | undefined
) => {
  if (user?.id) return getStoreParams(user)
  return getGuestStoreParamsSSR(ssrContext).guestStoreParams
}

export const useCombinedStoreParams = () => {
  const retailerStoreParams = useStoreParams()
  const globalStoreParams = useStoreContext()
  return globalStoreParams?.store_id ? globalStoreParams : retailerStoreParams
}
