import {
  DEFAULT_ANNUAL_COST,
  DEFAULT_MONTHLY_COST,
  DEFAULT_MONTHLY_SNAP_EBT_COST,
  DEFAULT_MONTHLY_STUDENT_COST,
  DEFAULT_PPO_FEE,
} from '@/services/DeliveryPlans/constants'
import {
  type DeliveryPlansPackage,
  type FetchPackagesResponse,
  UnlimitedInterval,
} from '@/services/DeliveryPlans/types'

export const getAnnualPlan = (plans: DeliveryPlansPackage[]) => {
  const annualPlan =
    plans.find((p) => p.unlimited_interval === UnlimitedInterval.ANNUAL) ?? null
  const annualCost = annualPlan
    ? Number(((annualPlan.total_cost_cents ?? 0) / 100).toFixed(0))
    : DEFAULT_ANNUAL_COST
  return { annualPlan, annualCost }
}

export const getMonthlyPlan = (plans: DeliveryPlansPackage[]) => {
  const monthlyPlan =
    plans.find((p) => p.unlimited_interval === UnlimitedInterval.MONTHLY) ??
    null
  const monthlyCost = monthlyPlan
    ? Number(((monthlyPlan.total_cost_cents ?? 0) / 100).toFixed(2))
    : DEFAULT_MONTHLY_COST
  return { monthlyPlan, monthlyCost }
}

export const getStudentPlan = (plans: DeliveryPlansPackage[]) => {
  const studentPlan = plans.find((p) => p.plan_type === 'student') ?? null
  const monthlyCost = studentPlan
    ? Number(((studentPlan.total_cost_cents ?? 0) / 100).toFixed(2))
    : DEFAULT_MONTHLY_STUDENT_COST
  return { studentPlan, monthlyCost }
}

export const getSnapEBTPlan = (plans: DeliveryPlansPackage[]) => {
  const snapEBTPlan = plans.find((p) => p.plan_type === 'ebt') ?? null
  const monthlyCost = snapEBTPlan
    ? Number(((snapEBTPlan.total_cost_cents ?? 0) / 100).toFixed(2))
    : DEFAULT_MONTHLY_SNAP_EBT_COST
  return { snapEBTPlan, monthlyCost }
}

export const getTrialEligible = (plans: DeliveryPlansPackage[]) => {
  const maximumTrialEligibleDays = plans.reduce((acc, plan) => {
    const trialDuration = plan.trial_period_days || 0
    return trialDuration > acc ? trialDuration : acc
  }, 0)

  return {
    isTrialEligible: maximumTrialEligibleDays > 0,
    trialEligibleDays: maximumTrialEligibleDays,
  }
}

export const getSingleDeliveryFee = (
  packages: FetchPackagesResponse | undefined
) => {
  const feeInCents =
    packages?.data?.single_delivery_fee_cents ?? DEFAULT_PPO_FEE
  const singleDeliveryFee = feeInCents / 100
  return { singleDeliveryFee }
}
