import { detectKeyboardUser } from '@/utils/detectKeyboardUser'
import { useGhostingWithAccessToken } from '@/hooks/useGhostingWithAccessToken'
import { useDetectPopState } from '@/hooks/useDetectPopState'
import { medallia } from '@/lib/medallia'
import { setupSW } from '@/serviceWorker'
import { appVersion } from '@shared/constants/build'

export const useInitApp = () => {
  const ghostingWithAccessToken = useGhostingWithAccessToken()
  const detectPopState = useDetectPopState()

  return () => {
    import('ua-parser-js').then((mod) => {
      const { name: browserName, version: browserVersion } =
        new mod.default().getBrowser()
      // special case: output the troubleshooting info
      // eslint-disable-next-line no-console
      console.log(
        `%c
      ATTENTION!
  
      This is to be used for troubleshooting only if a Shipt team member specifically asks for the app version.
      Shipt will NEVER ask for your password or anything other than the browser info below when opening this console.
  
      App Version: ${appVersion}
      Browser: ${browserName}
      Browser Version: ${browserVersion}
    `,
        'color: #038767'
      )
    })
    import('@/lib/env').then((mod) => mod.checkEnvVariables())

    setupSW()
    ghostingWithAccessToken()
    detectKeyboardUser()
    detectPopState()
    medallia.setParams()
  }
}
