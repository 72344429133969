import ServerCookies from 'cookies'
import { insecureCookieOptions } from '@shared/constants/auth'
import { type GetServerSidePropsContext } from 'next'

const guestSSRCookieOptions = {
  ...insecureCookieOptions,
  domain: undefined, // Exclude the domain option to be consistent with the client cookie setter
  maxAge: 7 * 24 * 60 * 60 * 1000, // 7 days
}

export const setServerGuestCookie = <TValue>({
  name,
  value,
  ssrContext,
}: {
  name: string
  value: TValue
  ssrContext: GetServerSidePropsContext
}) => {
  const cookies = new ServerCookies(ssrContext.req, ssrContext.res)
  const stringifiedValue = JSON.stringify(value)
  ssrContext.req.cookies[name] = stringifiedValue
  cookies.set(name, encodeURIComponent(stringifiedValue), guestSSRCookieOptions)
}
