import { useMemo } from 'react'
import { isOnServer } from '@shared/constants/util'
import { createStore } from 'jotai'
import { isRunningTests } from '@/utils/environment'
import {
  type JotaiStore,
  type AnyWritableAtom,
  type AtomState,
} from '@/state/types'

export * from './types'

export class JotaiInitialValues {
  private map: Map<AnyWritableAtom, unknown>
  constructor() {
    this.map = new Map<AnyWritableAtom, unknown>()
  }

  setAtom<T extends AnyWritableAtom>({ atom, state }: AtomState<T>) {
    this.map.set(atom, state)
    return this
  }

  atomMap() {
    return this.map
  }
}

let _store: JotaiStore | undefined

export const configureJotaiStore = (): JotaiStore => {
  if (isRunningTests) {
    _store = undefined
  }
  return getJotaiStore()
}

export const getJotaiStore = (): JotaiStore => {
  // For SSG and SSR always create a new store. Very important not to reuse a store across requests on the server
  if (isOnServer()) return createStore()
  // otherwise, return memoized store if it's set
  if (_store) return _store
  // and if it's not set, memoize store and return it
  _store = createStore()
  return _store
}

export function useCreateJotaiStore(): JotaiStore {
  return useMemo(() => configureJotaiStore(), [])
}
