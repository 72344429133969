// It is allowed here
// eslint-disable-next-line no-restricted-imports
import NextImg, { type ImageProps as NextImgProps } from 'next/image'
import { useEffect, useState } from 'react'
import { useImageLoader } from '@/elements/Image/hooks'
import {
  type StaticRequire,
  type StaticImageData,
} from 'next/dist/shared/lib/get-img-props'
import { fallbackSrcTypes } from '@/elements/Image/utils'

// { type?: never } is added as a TypeScript support to prevent the CMS image object,
// which includes a type property, from being passed as the src.
type StaticImageDataWithoutType = StaticImageData & { type?: never }

interface ImageProps extends Omit<NextImgProps, 'fill' | 'sizes' | 'src'> {
  alt: string
  src: string | StaticRequire | StaticImageDataWithoutType
  fallbackType?: keyof typeof fallbackSrcTypes
}
type Props<T> = T extends boolean
  ? ImageProps & { fill?: boolean; unoptimized: boolean }
  : ImageProps & ({ fill: true; sizes: string } | { fill?: never | false })

// We added this fallback image since there are some old images from aws bucket that
// is not migrated over and hence instead of showing them a broken experience, we decided to show
// them some default image. Please reach out to #ask-discover in case of any changes around this.
const blockList = ['cloudfront', 'amazonaws']

function getFallbackOrSrc(src: ImageProps['src']) {
  if (
    typeof src === 'string' &&
    blockList.some((badSource) => src?.includes(badSource))
  ) {
    return fallbackSrcTypes.generic
  }

  return src
}

export const Image = <T,>({ src, fallbackType, ...props }: Props<T>) => {
  const initialSrc = src || (fallbackType ? fallbackSrcTypes[fallbackType] : '')
  const [imgSrc, setImgSrc] = useState(() => getFallbackOrSrc(initialSrc))

  useEffect(() => {
    setImgSrc(getFallbackOrSrc(initialSrc))
  }, [initialSrc])

  const loader = useImageLoader(imgSrc)

  const onError: ImageProps['onError'] = (e) => {
    props.onError?.(e)
    setImgSrc(fallbackSrcTypes.generic)
  }
  return <NextImg src={imgSrc} {...props} onError={onError} loader={loader} />
}
