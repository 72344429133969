import { type ExperimentType } from '@/services/Experiments/constants'

export enum ExperimentValue {
  Control = 'control',
  Variant = 'variant',
  Variation_1 = 'variation_1',
  Variation_2 = 'variation_2',
  Variation_3 = 'variation_3',
  Variation = 'variation',
}

export type AristotleExperiments = Record<
  ExperimentType,
  { value: ExperimentValue }
>

export interface AristotleResponse {
  variations: Partial<AristotleExperiments>
}

export interface AristotleTrack {
  key?: string //"experiment_price_position",
  variation?: string //"control",
  anonymous_id?: string //"43ad421a-6f21-4afa-b89d-1f295b50667e",
  user_id?: string // "1818136",
  metadata?: Record<string, unknown> //{ },
  user_traits?: {
    signed_in?: boolean //false,
    metro?: string // "Stockton",
    metro_id?: number //251,
    store?: string //"Target",
    store_id?: number //36,
    store_location_id?: number // 6686
    has_purchased?: boolean
    has_placed_first_order?: boolean
    zip?: string
  }
}
