import {
  type QueryFunctionContext,
  type QueryClient,
} from '@tanstack/react-query'
import { cleanFlags } from '@/services/FeatureFlags/utils'
import { apiGet } from '@/utils/dataFetching'
import {
  type FeatureFlagsKey,
  useMarketingFeatureFlagsQuery,
} from '@/services/FeatureFlags/queries'
import { type HttpFeatureFlagsResponse } from '@/services/FeatureFlags/types'

export const fetchFeatureFlags = async (
  context: QueryFunctionContext<FeatureFlagsKey>
) => {
  const [, storeParams] = context.queryKey
  try {
    const data = await apiGet<HttpFeatureFlagsResponse>({
      config: {
        url: 'feature-flags/v1/flagsWithContext',
        params: {
          ServiceName: 'Segway',
          Store: storeParams.store_id,
          Location: storeParams.store_location_id,
          Metro: storeParams.metro_id,
          User: storeParams.user_id,
        },
      },
      fetcherName: 'fetchFeatureFlags',
    })
    return data
  } catch {
    return { FeatureFlags: [] }
  }
}

export const fetchMarketingFeatureFlagsHttp = () =>
  apiGet<HttpFeatureFlagsResponse>({
    config: {
      url: 'feature-flags/v1/flagsWithContext',
      params: {
        ServiceName: 'segway',
        Store: 0,
        Location: 0,
        Metro: 0,
        User: 0,
      },
    },
    options: {
      includeAuthHeader: false,
    },
    fetcherName: 'fetchMarketingFeatureFlagsHttp',
  })

export const fetchMarketingFeatureFlags = async ({
  queryClient,
}: {
  queryClient?: QueryClient
}) => {
  try {
    const data = await queryClient?.fetchQuery({
      queryKey: useMarketingFeatureFlagsQuery.getKey(),
      queryFn: fetchMarketingFeatureFlagsHttp,
    })
    return cleanFlags(data)
  } catch (e) {
    return cleanFlags(undefined)
  }
}
