import { type NextApiRequest, type NextApiResponse } from 'next'
import { getAuth0SDK, isAuth0Enabled } from '.'
import {
  forbidCaching,
  getAccessTokenFromSession,
  getIsAuthenticated,
  getMfaAccessTokenFromSession,
  getSession,
} from '@/serverUtils/auth/helpers'
import { logDebug, logError } from '@/utils/logger'
import { GuestDataStore } from '@/constants/global'
import { VISA_ENROLLMENT_KEY } from '@/utils/visa'
import { isValidRoute, RouteName, routes } from '@shared/constants/routes'
import {
  Auth0Scope,
  customClaims,
  MFA_STEP_UP_ERROR_MESSAGE,
} from '@/serverUtils/auth/constants'
import { type LoginOptions, type Session } from '@auth0/nextjs-auth0'
import { isErrorMessage } from '@/utils/guards/error'
import {
  checkHasValidPromotion,
  checkHasQueryParamForRouting,
  validateCouponCode,
} from '@/serverUtils/ssrHelpers/utils'
import { parseUrl, stringify } from '@/utils/queryString'
import { isValidRedeemType } from '@/utils/validator'
import { getSearchQueryString } from '@/utils/url'
import { GuestHasCartQuery } from '@/constants/ungatedParams'
import { getCartConversionRoute } from '@/services/Cart/utils'
import { isObject } from '@/utils/isObject'
import CookieHelper from 'cookies'
import { loginCompletedTriggerCookieName } from '@shared/constants/auth'
import { UNIVERSAL_ID } from '@shared/constants/segment'
import { getErrorMessage } from '@/utils/dataFetching/utils'

const { OPENID, PROFILE, EMAIL, IS_CUSTOMER, MANAGE_ACCOUNT } = Auth0Scope
const MFA_SCOPE = `${OPENID} ${PROFILE} ${EMAIL} ${IS_CUSTOMER} ${MANAGE_ACCOUNT}`

const getRequestHandlerStub =
  (enabled = false, configured?: boolean) =>
  (_: NextApiRequest, res: NextApiResponse) => {
    res.status(400).json({ enabled, configured })
  }

/**
 * Request handler that handles token retrieval. The token is
 * automatically refreshed if needed, or forcibly if ?refresh=1
 * is present. For security purposes, only the accessToken and
 * expiresAt timestamp are returned to the frontend (no refreshToken)
 * @param req - The incoming request object
 * @param res - The outgoing response object
 */
export const tokenRequestHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  forbidCaching(res)
  const isAuthenticated = await getIsAuthenticated(req, res)
  if (!isAuthenticated) {
    return res.status(200).json({})
  }
  if (req.method !== 'GET') {
    return res.status(405).json({ code: 405 })
  }
  const forceRefresh = req.query.refresh === '1'
  try {
    const accessToken = await getAccessTokenFromSession(req, res, {
      forceRefresh,
    })
    const expiresAt = (await getSession(req, res))?.accessTokenExpiresAt
    res.status(200).json({
      accessToken,
      expiresAt,
    })
  } catch (e) {
    if (e instanceof Error) {
      logError(e, { universal_id: req.cookies?.[UNIVERSAL_ID] })
    }
    res.status(401).json({ code: 401 })
  }
}

/**
 * Request handler that handles MFA token retrieval. Only the
 * accessToken and expiresAt timestamp are returned to the frontend.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 */
export const mfaTokenRequestHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  forbidCaching(res)
  if (req.method !== 'GET') {
    return res.status(405).json({ code: 405 })
  }
  try {
    const { accessToken, expiresAt } = await getMfaAccessTokenFromSession(
      req,
      res
    )
    res.status(200).json({ accessToken, expiresAt })
  } catch (e) {
    if (e instanceof Error) {
      logDebug(e.message)
    }
    res.status(401).json({ code: 401 })
  }
}

/**
 * Request handler that handles configuration options for the login route.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 */
export const loginRequestHandler = (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  const { mfa, redeem, returnTo, login_hint, migration } = req.query ?? {}
  if (mfa === 'true') {
    return sdk.handleLogin(req, res, {
      authorizationParams: {
        scope: MFA_SCOPE,
        prompt: 'login',
      },
    })
  }

  const isRedeemFlow = isValidRedeemType(String(redeem) ?? '')
  // nextParam is basically to handle any next queryParam value
  const returnToString = returnTo?.toString() || ''
  let destination = isValidRoute(returnToString)
    ? returnToString
    : routes.GLOBAL_HOMEPAGE.url

  if (checkHasQueryParamForRouting({ req })) {
    destination = `${routes.CHOOSE_MEMBERSHIP.url}?${stringify(req.query)}`
  }

  if (isRedeemFlow) {
    destination = `${routes.REDEEM_PAYMENT.url}?redeem=${redeem}`
  }

  return sdk.handleLogin(req, res, {
    authorizationParams: {
      screen_hint: 'login',
      // This custom query param is used to show custom copy on universal login pages..
      ...(isRedeemFlow && { 'ext-giftCardFlow': String(redeem) }),
      ...(login_hint && { login_hint: String(login_hint) }),
      // This can be removed once we've completed Auth0 migration
      ...(migration && { 'ext-migration': true }),
    },
    returnTo: destination,
  })
}

/**
 * Request handler that handles configuration options for the signup route.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 */
export const signupRequestHandler = (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  const { from, hideGuest, redeem } = req.query ?? {}
  const hideGuestOption = Boolean(
    hideGuest ||
      from === RouteName.UNIVERSAL_PRODUCT ||
      req.cookies[GuestDataStore.ADDRESS] ||
      req.cookies[VISA_ENROLLMENT_KEY]
  )

  const routingParamsExists = checkHasQueryParamForRouting({ req })
  const stringifiedParams =
    routingParamsExists && Object.keys(req.query).length
      ? `?${stringify(req.query)}`
      : ''
  const nextParam = req.query.next ? `?next=${req.query.next}` : ''
  const isRedeemFlow = isValidRedeemType(String(redeem) ?? '')

  return sdk.handleLogin(req, res, {
    authorizationParams: {
      screen_hint: 'signup',
      // This custom query param is used to hide the guest option
      // on the Auth0 signup page for users who are: guests, part
      // of the visa flow, or coming from an ungated PDP.
      ...(hideGuestOption && { 'ext-hideGuest': true }),
      ...(isRedeemFlow && { 'ext-giftCardFlow': String(redeem) }),
    },
    returnTo: `${routes.WELCOME.url}${nextParam || stringifiedParams}`,
  })
}

/**
 * Request handler that customizes the default callback handler without overriding it.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 */
export const callbackRequestHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  try {
    await sdk.handleCallback(req, res, { afterCallback })
  } catch (e) {
    handleCallbackError(e, req, res)
  }
}

const handleCallbackError = (
  error: unknown,
  req: NextApiRequest,
  res: NextApiResponse
) => {
  if (!isErrorMessage(error)) return
  logError(error, { universal_id: req.cookies?.[UNIVERSAL_ID] })

  const verifyEmailErrorRegex = /verify.*email|email.*verify/i
  const isEmailVerificationError =
    isObject(error) &&
    isObject(error.cause) &&
    typeof error.cause?.errorDescription === 'string' &&
    verifyEmailErrorRegex.test(error.cause?.errorDescription)

  const isMFAStepUpError = getErrorMessage(error).includes(
    MFA_STEP_UP_ERROR_MESSAGE
  )

  if (isMFAStepUpError) {
    res.setHeader('Location', `${routes.ADVANCED_SECURITY.url}?mfa_error=true`)
  } else if (isEmailVerificationError) {
    res.setHeader('Location', routes.EMAIL_VERIFICATION_REQUIRED.url)
  } else {
    res.setHeader('Location', routes.AUTH0_ERROR.url)
  }

  res.status(307).json({ code: 307 })
}

/**
 * Custom callback handler that stores the MFA access token on the previous session and redirects users to appropriate
 * route if there are active email domain promotions.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 * @param session - The current session
 * @returns The previous session with the MFA access token when the MFA scope is present. Otherwise, the current session is returned.
 */
export const afterCallback = async (
  req: NextApiRequest,
  res: NextApiResponse,
  session: Session,
  state: LoginOptions | undefined
) => {
  // Previous session is null on initial login
  const prevSession = await getSession(req, res)
  if (!prevSession) {
    const user = session.user
    // This will help us know that this is happening on login session and not signup.
    const isOnboarded = Boolean(user[customClaims.ONBOARDED])

    if (isOnboarded) {
      // Initial login, already onboarded; set cookie to trigger Login Completed tracking call
      const cookies = new CookieHelper(req, res)
      cookies.set(loginCompletedTriggerCookieName, '1', {
        // httpOnly false so it is accessible from JS
        // no expires; will be a session cookie; fine since this is ephemeral
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
      })

      // This is to verify if there are any active promotion while login
      const queryParams = parseUrl(state?.returnTo ?? '').query

      if (queryParams.guest === GuestHasCartQuery) {
        // We want to check if guestParam existst then run that flow in
        const cartConversionRoute = getCartConversionRoute(queryParams)
        res.setHeader('Location', cartConversionRoute)

        return session
      }

      const [promoExists, hasCouponCode] = await Promise.all([
        checkHasValidPromotion({
          email: user?.email ?? '',
          queryParams,
        }),
        validateCouponCode({ queryParams }),
      ])

      if (promoExists || hasCouponCode) {
        /*
          promoExists boolean is going to be true even if the email domain promo is present 
          or a regular signupPromotion is present. And hence we will first check if query params exists,
          if they do then we append email to the end which can be empty or just have email in param which will have value.
        */
        const stringifiedParams = getSearchQueryString({
          ...queryParams,
          email: user.email,
        })

        res.setHeader(
          'Location',
          `${routes.CHOOSE_MEMBERSHIP.url}${stringifiedParams}`
        )
      }
    }

    // Return the current session if there is no previous session
    return session
  }

  // If the MFA scope is present, store the MFA access token on the previous session
  if (session.accessTokenScope?.includes(Auth0Scope.MANAGE_ACCOUNT)) {
    // If user signs into a different account during MFA step-up,
    // throw an error and do not set MFA scoped token to session
    if (
      prevSession.user?.[customClaims.SHIPT_USER_ID] !==
      session.user?.[customClaims.SHIPT_USER_ID]
    ) {
      throw new Error(MFA_STEP_UP_ERROR_MESSAGE)
    }

    prevSession.mfaAccessToken = {
      accessToken: session.accessToken,
      accessTokenScope: session.accessTokenScope,
      accessTokenExpiresAt: session.accessTokenExpiresAt,
    }
  }

  return prevSession
}

export const logoutRequestHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  const returnTo = req.query?.returnTo?.toString() || ''
  return sdk.handleLogout(req, res, {
    returnTo: isValidRoute(returnTo) ? returnTo : undefined,
  })
}

export const getAuthRequestHandler = () => {
  const sdk = getAuth0SDK()
  const isEnabled = isAuth0Enabled()
  if (!sdk) {
    return getRequestHandlerStub(isEnabled, false)
  }
  if (!isEnabled) {
    return getRequestHandlerStub(isEnabled)
  }

  /**
   * Sets up a request handler for /api/auth/{slug},
   * where slug is specified as a property on the config object,
   * or one of the default: login, logout, callback, me
   */
  return sdk.handleAuth({
    login: loginRequestHandler,
    signup: signupRequestHandler,
    token: tokenRequestHandler,
    mfa_token: sdk.withApiAuthRequired(mfaTokenRequestHandler),
    callback: callbackRequestHandler,
    logout: logoutRequestHandler,
  })
}
