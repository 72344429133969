import { getAuthClient } from '@/utils/authentication/client'
import { cleanUrl } from '@/utils/url'
import { parse } from '@/utils/queryString'
import { useFetchUserQuery } from '@/services/User/hooks'
import { useGuestUserContext } from '@/context/GuestUserContext'

export const useGhostingWithAccessToken = () => {
  const fetchUserQuery = useFetchUserQuery()
  const { clearGuestCookie } = useGuestUserContext() ?? {}

  return (): boolean => {
    // We try to pull auth out of the fragment first and then fall back to the
    // query string because the change to use the fragment instead in this app will
    // roll out before the corresponding change in admin, but we don't want to
    // break the functionality in the interim.
    //
    // This should be removed once the relevant change in shipt-admin is live.
    //
    // See also: https://github.com/shipt/shipt-admin/pull/5645 and
    // https://github.com/shipt/sg1-member-module/pull/133
    let authData = parse(window.location.hash.replace('#', ''))

    if (!authData.Authorization) {
      authData = parse(window.location.search)
    }

    const authorization = authData.Authorization
    const expires_at = authData.expires_at

    if (typeof authorization !== 'string' || typeof expires_at !== 'string') {
      return false
    }
    clearGuestCookie?.()
    window.alert(
      "You are now ghosting into a member's account. Be cautious when making account changes and remember to log out when you are done."
    )
    cleanUrl()

    // expires_at and access_token are the only values provided through the URL query string in ghosting.
    // Admin users do not get a refresh_token and have 60 minutes until their token expires.
    getAuthClient().persistence.setAuthData({
      expires_at: String(expires_at),
      access_token: String(authorization).replace('Bearer ', ''),
      is_ghosted: true,
    })
    fetchUserQuery()
    return true
  }
}
