import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type AristotleExperiments,
  type AristotleResponse,
} from '@/services/Experiments/types'
import { apiGet } from '@/utils/dataFetching'
import { type ExperimentsQueryKey } from '@/services/Experiments/queries'
import { removeFalseyValues } from '@/utils/removeFalseyValues'
import { getObjectEntries, getObjectFromEntries } from '@/utils/types'
import { defaultExperiments } from '@/services/Experiments/defaultExperiments'

export const fetchAllExperiments = async (
  context: QueryFunctionContext<ExperimentsQueryKey>
): Promise<AristotleExperiments> => {
  const [
    ,
    {
      storeParams,
      has_placed_first_order,
      has_purchased,
      has_subscription,
      is_exempt,
    },
  ] = context.queryKey
  const signed_in = Boolean(storeParams?.user_id)
  // Aristotle doesn't like null query params
  const params = {
    ...removeFalseyValues(storeParams),
    signed_in,
    has_placed_first_order,
    has_purchased,
    has_subscription,
    is_exempt,
  }

  const response = await apiGet<AristotleResponse>({
    config: { url: 'aristotle/v2/all', params },
    context,
    fetcherName: 'fetchAllExperiments',
  })

  return getObjectFromEntries(
    getObjectEntries(defaultExperiments).map(([experimentType, value]) => {
      return [experimentType, response.variations[experimentType] ?? value]
    })
  )
}
