import { useQuery, queryOptions } from '@tanstack/react-query'
import {
  fetchFeatureFlags,
  fetchMarketingFeatureFlagsHttp,
} from '@/services/FeatureFlags/fetchers'
import { minutesToMilliseconds } from 'date-fns'
import {
  type StoreParams,
  useStoreParams,
} from '@/utils/dataFetching/storeParams'

const getFeatureFlagsQueryKey = (storeParams: StoreParams) =>
  ['featureFlags', storeParams] as const
export type FeatureFlagsKey = ReturnType<typeof getFeatureFlagsQueryKey>

export const featureFlagsQueryOptions = (storeParams: StoreParams) =>
  queryOptions({
    queryKey: getFeatureFlagsQueryKey(storeParams),
    queryFn: fetchFeatureFlags,
  })

export const useQueryFeatureFlags = () => {
  const storeParams = useStoreParams()
  return useQuery({
    ...featureFlagsQueryOptions(storeParams),
    placeholderData: (previousData) => previousData,
    enabled: Boolean(storeParams.user_id),
    staleTime: minutesToMilliseconds(10),
  })
}

const fetchMarketingFeatureFlagsKey = ['marketingFeatureFlags'] as const

export const useMarketingFeatureFlagsQuery = () =>
  useQuery({
    queryKey: fetchMarketingFeatureFlagsKey,
    queryFn: fetchMarketingFeatureFlagsHttp,
    placeholderData: (previousData) => previousData,
    staleTime: minutesToMilliseconds(10),
  })

useMarketingFeatureFlagsQuery.fetcher = fetchMarketingFeatureFlagsHttp
useMarketingFeatureFlagsQuery.getKey = () => fetchMarketingFeatureFlagsKey
