import { breakpoints } from '@/styles/media'
import styled from 'styled-components'
import { ColorButton } from '@/elements/Buttons/ButtonColors'

/**
 * @deprecated Use { Button } from '@shipt/design-system-buttons' instead
 */
export const ColorPillButton = styled(ColorButton)`
  font-size: 0.8125rem;
  border-radius: 2.5rem;
  padding: 0.3125rem 0.9375rem;

  @media ${breakpoints.medium} {
    padding: 0.5625rem 0.9375rem;
  }
`
