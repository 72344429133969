import { BREAKPOINTS } from '@shipt/design-system-themes'

/** @deprecated - use screenSizes from "@shipt/design-system-themes" */
export const breakpoints = {
  small: `(max-width: ${BREAKPOINTS.MAX_MEDIUM}px)`,
  medium: `(min-width: ${BREAKPOINTS.MEDIUM}px)`,
  mediumOnly: `(min-width: ${BREAKPOINTS.MEDIUM}px) and (max-width: ${BREAKPOINTS.MAX_LARGE}px)`,
  large: `(min-width: ${BREAKPOINTS.LARGE}px)`,
  xlarge: `(min-width: ${BREAKPOINTS.XLARGE}px)`,
}
