import styled from 'styled-components'
import { IconButton } from '@shipt/design-system-buttons'

export * from './ButtonColors'
export * from './ButtonVariants'

export const CarouselButton = styled(IconButton).attrs({
  variant: 'primary',
  surface: 'inverse',
})`
  filter: drop-shadow(0 0.125rem 0.25rem rgb(24 12 32 / 20%));
`

export const TransparentButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
