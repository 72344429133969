/*

👴🛹 Hello fellow engineer! 👴🛹
When adding new features to segway, you should put them behind a feature flag.
This will allow you to toggle them on and off in shipt admin.

Staging - https://staging-admin.shipt.com/feature-flags/segway
Production - https://admin.shipt.com/feature-flags/segway

It's okay if you don't add the new flag in admin right away, the flag values
will default to the values below. If you put something in the list below but not
in admin, the value you put here will be used in staging and production.

 */

export const defaultFlags = {
  address_mutations_bff: false,
  enterprise_graphql: false,
  gift_membership_promo: false,
  high_capacity_vehicles: false,
  new_lists_badge: false,
  picture_proof_of_delivery: false,
  privacy_intake_form_limit_spi_option: false,
  request_call_from_shopper: false,
  smaller_porthole_image: false,
  sms_notifications: false,
  snap_ebt: false,
  snap_ebt_eligible: false,
  snap_ebt_memberships: false,
  snap_ebt_memberships_entry_modal: false,
  snap_ebt_post_order_modification: false,
  student_memberships: false,
  usuals_list: false,
  visa_enrollment: false,
}
